import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
let SyngooFaleConosco = class SyngooFaleConosco extends Vue {
    openWhatsApp() {
        open('https://api.whatsapp.com/send?phone=554730500070');
    }
};
SyngooFaleConosco = __decorate([
    Component
], SyngooFaleConosco);
export default SyngooFaleConosco;
