import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { syngooConfiguracaoStore } from '@/store';
import { newSyngooConfiguracao } from '@/interfaces/syngooConfiguracao';
import SyngooFaleConosco from '@/components/SyngooFaleConosco.vue';
let Configuracao = class Configuracao extends Vue {
    constructor() {
        super(...arguments);
        this.entityObject = {};
        this.loading = false;
    }
    async submit() {
        this.loading = true;
        if (this.entityObject.id) {
            await syngooConfiguracaoStore.updateSyngooConfiguracao(this.entityObject);
        }
        else {
            await syngooConfiguracaoStore.createSyngooConfiguracao(this.entityObject);
        }
        this.loading = false;
    }
    async mounted() {
        if (this.propCompanyId) {
            this.entityObject =
                await syngooConfiguracaoStore.getSyngooConfiguracaoById(this.propCompanyId);
        }
        else {
            this.entityObject = await syngooConfiguracaoStore.getSyngooConfiguracao();
        }
        if (!this.entityObject) {
            this.entityObject = newSyngooConfiguracao();
        }
    }
};
__decorate([
    Prop({ default: null })
], Configuracao.prototype, "propCompanyId", void 0);
Configuracao = __decorate([
    Component({
        components: {
            SyngooFaleConosco,
        },
    })
], Configuracao);
export default Configuracao;
